<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Detail Work Order">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
        @click="handleBack()">Back</vs-button>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Work Order ID </span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input class="w-full" v-model="code" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Work Order Description</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input class="w-full" v-model="description" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Bill Of Material</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
              :filterable="true" :allow-empty="true" :group-select="true" :max-height="100" :limit="3"
              :internal-search="false" placeholder="Type to search" :searchable="true" track-by="id" label="Code"
              :disabled="true" @select="handleBoM()" />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Purpose</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
                            :filterable="true" :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            :internal-search="false" :select-label="''" :deselect-label="''"
                            placeholder="Type to search" :searchable="true" track-by="id" label="Code"
                            @select="handleBoM()" /> -->
            <multiselect :allowEmpty="false" :options="optionPurpose" v-model="selectedPurpose" :select-label="''"
              :deselect-label="''" :label="'name'" :track-by="'name'" :placeholder="''" :disabled="true" />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect class="selectExample" v-model="selectedWarehouse" :options="optionWarehouse" :multiple="false"
              :allow-empty="false" :group-select="false" :max-height="100" :limit="3" placeholder="Type to search"
              :searchable="true" :custom-label="customLabelWarehouse" :disabled="true" />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Warehouse Area</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect class="selectExample" v-model="selectedWarehouseArea" :options="optionWarehouseArea"
              :multiple="false" :select-label="''" :deselect-label="''" :allow-empty="false" :group-select="false"
              :max-height="100" :limit="3" placeholder="Type to search" :searchable="true"
              :custom-label="customLabelWarehouseArea" :disabled="true" />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Request Date</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <vs-input v-model="requestDate" class="w-full" type="date" name="requestDate" :min="now"
              disabled></vs-input> -->
            <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="requestDate" disabled>
            </datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Execution Date</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="executionDate" disabled>
            </datepicker>
            <!-- <vs-input v-model="executionDate" class="w-full" type="date" name="executionDate" :min="minExecutionDate"
              disabled></vs-input> -->
          </div>
        </div>
      </div>

      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-textarea v-model="note" class="w-full" type="date" name="note" disabled></vs-textarea>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <label>Attachment
              <small style="color: red">(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small></label>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <div class="vx-col w-1/2">
              <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
              <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file" multiple="multiple"
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls" />
            </div>
          </div>
        </div>
        <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
          <div class="vx-col sm:w-1/4 w-full">
            <span></span>
          </div>
        </div>
        <!-- display: block -->
        <vs-divider style="width: 60%; margin-left: 30%">
          List Attachment
        </vs-divider>
        <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                v-for="tr in fileAttachment">
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button type="line" icon-pack="feather" icon="icon-eye" @click.stop="handleShowAttachment(tr)" />
                  </vx-tooltip>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
    <h3><b>Parent (Result)</b></h3>
    <hr />
    <br />
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base m-10">
        <table width="100%" class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th width="15%">SKU Code</th>
              <th width="15%">SKU Name</th>
              <th width="7%">Qty</th>
              <th width="7%">Unit</th>
              <th width="7%">Qty UOM</th>
              <th width="15%">MAP</th>
              <th width="10%">Sub Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td vs-table--td" style="padding: 5px">
                <vs-input class="w-full" v-model="itemParent.sku_code" disabled />
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <vs-input class="w-full" v-model="itemParent.name" disabled />
              </td>


              <td class="td vs-table--td" style="padding-right: 10px">
                <vs-input class="w-full" type="number" v-model="itemParent.qty" :min="1" disabled />
              </td>

              <td class="td vs-table--td" style="padding-right: 5px">
                <vs-input class="w-full" v-model="itemParent.unit" disabled />
              </td>

              <td class="td vs-table--td" style="padding-right: 10px">
                <vs-input class="w-full" type="number" v-model="itemParent.qty_uom" :min="1" disabled />
              </td>

              <td class="td vs-table--td" style="padding-right: 10px">
                <vs-input class="w-full" type="text" :value="formattedItemParentPrice" :min="1" disabled />
              </td>
              <td class="td vs-table--td align-top" style="padding: 5px">
                <vs-input class="w-full" type="text" v-model="itemParent.subTotalText" readonly :min="0"></vs-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h3><b>Child (Source)</b></h3>
    <hr />
    <br />

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base m-10">
        <table width="100%" class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th width="15%">SKU Code</th>
              <th width="15%">SKU Name</th>
              <th width="7%">Qty</th>
              <th width="7%">Unit</th>
              <th width="7%">Qty UOM</th>
              <th width="10%">MAP</th>
              <th width="10%">Batch</th>
              <th width="10%">Sub Total</th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(child, index) in itemLines" :key="index" style="padding-bottom: 5px">
              <td class="td vs-table--td" style="padding: 5px">
                <vs-input class="w-full" v-model="itemLines[index].sku_code" disabled />
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <vs-input class="w-full" v-model="itemLines[index].name" disabled />
              </td>
              <td class="td vs-table--td" style="padding-right: 10px">
                <vs-input class="w-full" type="number" v-model="itemLines[index].qty" :min="1" disabled />
              </td>
              <td class="td vs-table--td" style="padding-right: 5px">
                <vs-input class="w-full" v-model="itemLines[index].unit" disabled />
              </td>
              <td class="td vs-table--td" style="padding-right: 5px">
                <vs-input class="w-full" v-model="itemLines[index].qty_uom" disabled />
              </td>
              <td class="td vs-table--td" style="padding-right: 5px">
                <vs-input class="w-full" type="text" :value="formattedItemChildPrice(child.price)" disabled />
              </td>
              <td class="td vs-table--td" style="padding-right: 5px">
                <vs-input class="w-full" v-model="itemLines[index].batch" disabled />
              </td>
              <td class="td vs-table--td" style="padding-right: 5px">
                <vs-input class="w-full" v-model="itemLines[index].subTotalText" disabled />
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th width="20%"></th>
              <th width="20%"></th>
              <th width="20%"></th>
              <th width="10%"></th>
              <th>TOTAL</th>
              <th>{{ total }}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  props: {
    title: {
      type: String,
    },
    action: {
      type: String,
    },
  },
  components: {
    Datepicker
  },
  mounted() {
    this.getData(this.$route.params.id);
    this.getBillOfMaterial();
    this.getWarehouse();
  },
  computed: {
    formattedItemParentPrice() {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 7,
        maximumFractionDigits: 7,
      }).format(this.itemParent.price)
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    requestDate: function (val) {
      this.minExecutionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
      this.executionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
    },
    // "itemParent.qty": function (val) {
    //   if (this.itemParent.qty > 0) {
    //     if (val % 2 == 0) {
    //       this.itemLines = this.itemLines.map((el) => {
    //         return {
    //           id: el.id,
    //           item_unit_id: el.item_unit_id,
    //           sku_code: el.sku_code,
    //           name: el.name,
    //           unit: el.unit,
    //           qty_old: el.qty_old,
    //           qty: 1,
    //         };
    //       });
    //     } else {
    //       val = val - 1;
    //       this.itemLines = this.itemLines.map((el) => {
    //         return {
    //           id: el.id,
    //           item_unit_id: el.item_unit_id,
    //           sku_code: el.sku_code,
    //           name: el.name,
    //           unit: el.unit,
    //           qty_old: el.qty_old,
    //           qty: Math.ceil((val / this.itemParent.qty_old) * el.qty_old),
    //         };
    //       });
    //       //then add 1 to the first element
    //       this.itemLines[0].qty = this.itemLines[0].qty + 1;
    //     }
    //   } else {
    //     this.itemLines = this.itemLines.map((el) => {
    //       return {
    //         id: el.id,
    //         item_unit_id: el.item_unit_id,
    //         sku_code: el.sku_code,
    //         name: el.name,
    //         unit: el.unit,
    //         qty_old: el.qty_old,
    //         qty: 0,
    //       };
    //     });
    //   }
    // },
  },
  data: () => ({
    optionItem: [],
    optionPurpose: [
      {
        value: 0,
        name: "SKU-ID Change",
      },
      {
        value: 1,
        name: "Repackaging",
      },
    ],
    selectedPurpose: null,
    itemLines: [
      {
        id: null,
        item_unit_id: null,
        sku_code: "",
        name: "",
        unit: "",
        qty: 0,
        qty_old: 0,
        qty_uom: 0,
        batch: "",
        price: 0,
        subTotal: 0,
        subTotalText: "0"
      },
    ],
    itemParent: {
      item_unit_id: null,
      sku_code: "",
      name: "",
      unit: "",
      qty: 0,
      qty_old: 0,
      price: 0,
      qty_uom: 0,
      subTotal: 0,
      subTotalText: "0"
    },
    optionBom: [],
    selectedBom: null,
    selectedWarehouse: { name: "", id: null },
    optionWarehouse: [],
    optionWarehouseArea: [],
    selectedWarehouseArea: null,
    requestDate: moment().format("YYYY-MM-DD"),
    minExecutionDate: moment().add(1, "days").format("YYYY-MM-DD"),
    executionDate: moment().add(1, "days").format("YYYY-MM-DD"),
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: "0",
    totalPage: 0,
    totalSearch: 0,
    code: "",
    description: "",
    note: "",
    attachment: "",
    fileAttachment: [],
    IDAttachment: [],
    now: moment().add(0, "days").format("YYYY-MM-DD"),
    IDs: null,
    isDisabled: true,
  }),
  methods: {
    formattedItemChildPrice(price) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 7,
        maximumFractionDigits: 7,
      }).format(price)
    },
    handleBack() {
      this.$router.back()
    },

    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      // objectURL = URL.createObjectURL(file.File);

      // // link.download = file.name; // this name is used when the user downloads the file
      // var href = objectURL;
      // window.open(href, "_blank").focus();

      objectURL = file.PathFile;

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },

    getBillOfMaterial() {
      this.$vs.loading();
      this.$http
        .get("api/wms/v1/work-order/item-bill-of-material", {
          params: {
            length: null,
            order: "id",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionBom = resp.data.records;
              if (this.optionBom.length > 0) {
                // this.selectedBom = this.optionBom[0];
              } else {
                this.optionBom = [];
                this.selectedBom = {};
              }
            } else {
              this.optionBom = [];
              this.selectedBom = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    async getWarehouse() {
      this.$vs.loading();
      const response = await this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })

      return response.data.records
      // .then((resp) => {
      //   if (resp.code == 200) {
      //     if (resp.data.records) {
      //       this.optionWarehouse = resp.data.records;
      //       if (this.optionWarehouse.length > 0) {
      //         // this.selectedWarehouse = this.optionWarehouse[0];
      //       } else {
      //         this.optionWarehouse = [];
      //         this.selectedWarehouse = {};
      //       }
      //     } else {
      //       this.optionWarehouse = [];
      //       this.selectedWarehouse = {};
      //     }
      //     this.$vs.loading.close();
      //   } else {
      //     this.$vs.loading.close();
      //   }
      // });
    },
    async getWarehouseArea(warehouseID) {
      this.$vs.loading();
      const response = await this.$http
        .get("api/wms/v1/work-order/warehouse-area-by-warehouse", {
          params: {
            order: "name",
            sort: "asc",
            warehouseID
          },
        })

      return response.data
      // .then((resp) => {
      //   if (resp.code == 200) {
      //     if (resp.data) {
      //       this.optionWarehouseArea = resp.data;
      //       if (this.optionWarehouseArea.length < 0) {
      //         this.optionWarehouseArea = [];
      //         this.selectedWarehouseArea = {};
      //       }
      //     } else {
      //       this.optionWarehouseArea = [];
      //       this.selectedWarehouseArea = {};
      //     }
      //     this.$vs.loading.close();
      //   } else {
      //     this.$vs.loading.close();
      //   }
      // })
      // .catch((error) => {
      //   this.$vs.loading.close();
      //   console.log(error);
      // });
    },
    customLableWarehouse({ name }) {
      return ` ${name}`;
    },
    customLabelWarehouse({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelWarehouseArea({ WarehouseAreaCode, WarehouseAreaName }) {
      return `${WarehouseAreaCode} - ${WarehouseAreaName}`;
    },
    handleBoM() {
      console.log("selectedBom", this.selectedBom);
      if (this.selectedBom) {
        this.itemParent = [];
        this.itemParent = {
          item_unit_id: this.selectedBom.ItemUnitID,
          sku_code: this.selectedBom.SkuCode,
          name: this.selectedBom.Name,
          unit: this.selectedBom.Unit,
          qty: this.selectedBom.Quantity,
          qty_old: this.selectedBom.Quantity,
        };

        this.itemLines = [];
        this.itemLines = this.selectedBom.ItemBillOfMaterialLines.map((el) => {
          return {
            item_unit_id: el.ItemUnitID,
            sku_code: el.SkuCode,
            name: el.Name,
            unit: el.Unit,
            qty: el.Quantity,
            qty_old: el.Quantity,
          };
        });
      } else {
        this.isDisabled = true;
      }
    },
    getData(id) {
      this.$vs.loading();
      this.$http
        .get("api/wms/v1/work-order/detail", {
          params: {
            id: id,
          },
        })
        .then(async (resp) => {
          if (resp.code == 200) {
            this.code = resp.data.code;
            this.IDs = resp.data.id;
            this.description = resp.data.description;
            this.note = resp.data.note;
            this.requestDate = resp.data.request_date;
            this.executionDate = resp.data.execution_date;
            this.selectedBom = {
              id: resp.data.item_bill_of_material_id,
              Code: resp.data.item_bom_code,
            };
            // this.selectedWarehouse = {
            //   id: resp.data.warehouse_id,
            //   name: resp.data.warehouse_name,
            // };

            const warehouseData = await this.getWarehouse()
            this.optionWarehouse = warehouseData
            this.selectedWarehouse = this.optionWarehouse.find(warehouse => warehouse.id == resp.data.warehouse_id)

            const warehouseAreaData = await this.getWarehouseArea(this.selectedWarehouse.id)
            this.optionWarehouseArea = warehouseAreaData
            this.selectedWarehouseArea = this.optionWarehouseArea.find(warehouseArea => warehouseArea.WarehouseAreaID == resp.data.warehouse_area_id)

            this.selectedPurpose = {
              value: resp.data.purpose_type,
              name: resp.data.purpose_name
            }

            this.itemParent = [];
            this.itemParent = {
              item_unit_id: resp.data.item_parent.item_unit_id,
              sku_code: resp.data.item_parent.sku_code,
              name: resp.data.item_parent.name,
              unit: resp.data.item_parent.unit,
              qty: resp.data.item_parent.quantity,
              qty_old: resp.data.item_parent.quantity_old,
              price: resp.data.item_parent.price,
              qty_uom: resp.data.item_parent.qty_uom
            };

            const currUOM = this.itemParent.qty_uom
            const currMAP = this.itemParent.price
            const currQTY = this.itemParent.qty

            const subTotal = currUOM * currMAP * currQTY

            const subTotalText = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 7,
              maximumFractionDigits: 7,
            }).format(subTotal)

            this.itemParent.subTotal = subTotal
            this.itemParent.subTotalText = subTotalText

            if (resp.data.attachment_data) {
              this.fileAttachment = resp.data.attachment_data.filter(attachment => attachment.deleted === "").map((attachment) => ({
                id: attachment.id,
                NameFile: attachment.file_name,
                PathFile: attachment.file_url
              }))
            }

            this.itemLines = [];
            if (resp.data.work_order_lines) {
              resp.data.work_order_lines.forEach((el) => {
                const currUOM = el.qty_uom
                const currMAP = el.price
                const currQTY = el.quantity

                const subTotal = currUOM * currMAP * currQTY
                const subTotalText = new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 7,
                  maximumFractionDigits: 7,
                }).format(subTotal)


                this.itemLines.push({
                  id: el.id,
                  item_unit_id: el.item_unit_id,
                  sku_code: el.sku_code,
                  name: el.name,
                  unit: el.unit,
                  qty: el.quantity,
                  qty_old: el.quantity_old,
                  batch: el.batch,
                  price: el.price,
                  qty_uom: el.qty_uom,
                  subTotal,
                  subTotalText
                });
              });

              this.sumTotal()
            }
          }
          this.$vs.loading.close();
        });
    },
    sumTotal() {
      const arrOfSubTotal = this.itemLines.map(item => item.qty * item.qty_uom * item.price)
      const total = arrOfSubTotal.reduce((a, b) => a + b, 0)
      const totalText = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 7,
        maximumFractionDigits: 7,
      }).format(total)
      this.total = totalText
    },
  },
};
</script>
